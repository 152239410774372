import React from "react";
import { WmkLink } from "wmk-link"
import { graphql, useStaticQuery } from "gatsby";

const StepsToApply = () => {
  const { appForm } = useStaticQuery(query);
  return (
    <>
      <div className="steps-title">
        <h3>STEPS TO APPLY</h3>
      </div>
      <div className="steps-copy">
        <ul>
          <li>
            <div>
              Fully complete the{" "}
              <WmkLink
                to={appForm?.asset?.file?.url}
                target="blank"
                style={{ display: "inline-block", margin: 0 }}
              >
                Application
              </WmkLink>{" "}
              for the open position of your interest.
            </div>
          </li>
          <li>
            <div>
              Submit the completed application in full, and attach it to an
              email addressed to:{" "}
              <WmkLink mailto style={{ display: "inline" }}>
                personnel@utrwd.com
              </WmkLink>
            </div>
          </li>
          <li>
            <div>
              It can also be printed and mailed to the address at the bottom of
              this page. You may submit an electronic copy of your resume with,
              but not in lieu of, the application. All questions on the
              application must still be answered completely.
            </div>
          </li>
          <li>
            <div>
              Only fully completed applications for currently open positions
              will be considered
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default StepsToApply;

const query = graphql`
  {
    appForm: contentfulMenuItem(title: { eq: "Our Application Form" }) {
      title
      asset {
        title
        file {
          url
        }
      }
    }
  }
`;
