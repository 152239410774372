import React from "react";
import styled from "styled-components";
import PageHero from "../components/common/PageHero";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../components/common/Button";
import { graphql } from "gatsby";
import FlexContent from "../components/flexible/FlexibleContent";
import { WmkSeo } from "wmk-seo";
import StepsToApply from "../components/routes/JoinOurTeam/StepsToApply";
import { colors } from "../vars/palette";
import { JobFields } from "./fragments/NodeJobFields";
import { PageFields } from "./fragments/NodePageFields";
import { BodyCopyFields } from "./fragments/BlockBodyCopyFields";
import { Page } from "../classes/Page";
import { RichText } from "wmk-rich-text";
import Theme from "../vars/ThemeOptions";

const ContentCont = styled(Container)`
  margin: 2rem auto;
  .buttons-wrap {
    margin: 2rem 0;
    display: flex;
    justify-content: space-around;
  }
  .job-col {
    color: ${colors.hex("secondary")};
    h3 {
      color: ${colors.hex("coaccent")};
      font-weight: bold;
      font-size: 2rem;
    }
    .title {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
  .steps-col {
    position: relative;
    border: 3px solid ${colors.hex("secondary")};
    display: flex;
    justify-content: center;
    align-items: center;
    .steps-title {
      position: absolute;
      z-index: 10;
      top: -1.75vw;
      left: 10%;
      padding: 0 1rem;
      background: ${colors.hex("white")};
      color: ${colors.hex("coaccent")};
      h3 {
        font-weight: bold;
        font-size: 2rem;
      }
    }
    .steps-copy {
      padding: 2rem 2vw 1rem 2vw;
      a {
        font-size: 1.25rem;
        color: ${colors.hex("accent")};
        display: block;
        text-align: center;
        margin: -0.75rem 0 1rem 0;
      }
    }
  }
  .bold {
    font-weight: bold;
  }
`;

export default ({ data }: { data: JobQuery }) => {
  const { job, notesBlock, appForm } = data;
  const page = new Page(data.page);
  const { jobSummary, linkToApplication } = job;
  return (
    <>
      <WmkSeo.Meta
        title={page.titleBar}
        description={page.meta}
        slug={job.slug}
        path={Theme.paths.job}
        siteTitle={"UTRWD"}
        baseUrl="https://www.utrwd.com"
      />
      <PageHero
        title={page.title}
        heroImage={page.image}
        titleBar={page.titleBar}
      />
      <ContentCont>
        <Row>
          <Col md={4} sm={12} className="steps-col">
            <StepsToApply />
          </Col>
          <Col xs={12} md={8} className="job-col">
            <h3>OPEN POSITION</h3>
            <div className="title">
              <p>{job.title}</p>
            </div>
            <div className="summary">
              <p>
                <span className="bold">Position Summary:</span>
                {` ` + jobSummary?.jobSummary}
              </p>
            </div>
            <div className="buttons-wrap">
              {linkToApplication?.file?.url && (
                <Button to={linkToApplication?.file?.url} target={`_blank`}>
                  View Position Announcement
                </Button>
              )}
              {appForm?.asset?.file?.url && (
                <Button to={appForm?.asset?.file?.url} target="blank">
                  Download Application Form
                </Button>
              )}{" "}
              {/* THIS LINK NEEDS TO BE FIXED WITH THE DYNAMIC SLUG FROM DATA BEFORE THIS COMPONENT IS COMPLETE (tried to use ${data.page.slug} in place of /join-our-team, but that forced /job/ right before join-our-team, resulting in a 404) */}
            </div>
          </Col>
        </Row>
      </ContentCont>
      <FlexContent
        blocks={[
          {
            ...notesBlock,
            waveOptions: "Top",
            pages: null,
            dividerPosition: "Center",
            style: "Blue Background",
            copy: new RichText(notesBlock.copy)
          }
        ]}
      />
    </>
  );
};

interface JobQuery {
  job: JobFields;
  page: PageFields;
  notesBlock: BodyCopyFields;
  appForm: { title: string; asset: { title: string; file: { url: string } } };
}

export const query = graphql`
  query jobQuery($slug: String!) {
    job: contentfulJobs(slug: { eq: $slug }) {
      ...NodeJobFields
    }
    page: contentfulPages(slug: { eq: "join-our-team" }) {
      ...NodePageFields
    }
    notesBlock: contentfulBlockBodyCopy(
      title: { eq: "Join Our Team: Important Notes" }
    ) {
      __typename
      ...BlockBodyCopyFields
    }
    appForm: contentfulMenuItem(title: { eq: "Our Application Form" }) {
      title
      asset {
        title
        file {
          url
        }
      }
    }
  }
`;
