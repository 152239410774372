import React from "react";
import styled from "styled-components";
import { PageBlocks } from "../../classes/Page";
import Body from "./blocks/BlockBody/BlockBody";

const FlexibleWrap = styled.section`
  // padding: 0.5rem 0 2rem 0;
  .layout-block:last-child {
    & > div:last-child {
      padding-bottom: 6vh;
    }
  }
`;

const Flexible = ({
  blocks,
  main
}: {
  blocks: PageBlocks[];
  main?: boolean;
}) => {
  // Dynamic ACF Flexible Content Blocks handling
  const wrapClass = ["flexible-content"];
  wrapClass.push(main ? "blocks-main" : "blocks-sec");
  return blocks && blocks.length ? (
    <FlexibleWrap className={wrapClass.join(" ")}>
      {/* Render any found Flexible content blocks */
      blocks
        ? blocks.map((block, i) => {
            return <Body key={block.title + i} block={block} />;
          })
        : null}
    </FlexibleWrap>
  ) : null;
};

export default Flexible;
