import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Wave from "./Wave/Wave";
import DivBot from "./Divider/DivBot";
import { colors } from "../../../../vars/palette";
import { PageBlocks } from "../../../../classes/Page";
import { options } from "../../../Blocks/richTextOptions";
//import { RichTextReact } from "wmk-rich-text";
import { RichTextReact } from "wmk-rich-text";

const BodyWrap = styled.article`
  max-width: 700px;
  margin: auto;
  padding: 2vw 0 0 0;
  overflow: hidden;
  position: relative;
  .p {
    padding: 0.5rem 0;
    a {
      color: ${colors.hex("accent")};
      &:hover {
        color: ${colors.hex("secondary")};
      }
    }
  }
  .body-copy-title {
    font-weight: bold;
    color: ${colors.hex("primary")};
    text-align: center;
    text-shadow: 3px 3px 4px ${colors.rgba("black", 0.16)};
  }
  &.blue {
    .wmk-link {
      color: ${colors.hex("white")};
      background-color: none;
      text-decoration: underline !important;
      &:hover: {
        color: ${colors.hex("accent")};
        background-color: none;
      }
    }
    .utrwd-btn {
      background-color: ${colors.hex("white")};
      color: ${colors.hex("primary")};
      text-decoration: none !important;
      &:hover {
        background-color: ${colors.hex("primary")};
        color: ${colors.hex("white")};
      }
    }
  }
`;

const ContWrap = styled(Container)`
  // padding: 0.5rem 0 1.5rem 0;
  position: relative;
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    color: ${colors.hex("primary")};
    code {
      font-weight: bold;
      text-align: center;
      display: block;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
    }
  }
  h3 {
    color: ${colors.hex("secondary")};
    padding: 3vh 0 2vh 0;
  }
  p {
    code {
      text-align: center;
      display: block;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
    }
    a {
      color: ${colors.hex("accent")};
    }
  }
  &.blue {
    p {
      a {
        color: ${colors.hex("white")};
        font-weight: bold;
        text-decoration: underline;
      }
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.hex("white")};
    }
    background: ${colors.hex("secondary")};
    color: ${colors.hex("white")};
    .body-copy-title {
      color: ${colors.hex("white")};
    }
    // .wmk-link {
    //   color: ${colors.hex("primary")};
    //   background-color: ${colors.hex("white")};
    //   &:hover {
    //     color: ${colors.hex("white")};
    //     background-color: ${colors.hex("primary")};
    //   }
    //   &.inline {
    //     background: none;
    //     font-weight: 400;
    //     color: ${colors.hex("white")};
    //   }
    // }
    .droplet {
      .bullet-drop-stroke {
        fill: ${colors.hex("white")};
      }
    }
  }
  .embedded-image-wrap {
    button {
      appearance: none;
      border: none;
      background: none;
    }
    .embedded-image {
      max-width: 100%;
    }
    .embedded-image-caption {
      width: 100%;
      color: ${colors.hex("secondary")};
      display: block;
      font-size: 0.9rem;
      padding: 0.5vh 1rem 1vh 1rem;
    }
  }
  .embedded-video {
    display: flex;
    justify-content: center;
    padding: 2vh 0 4vh 0;
  }
  .embedded-file {
    display: flex;
    justify-content: center;
    padding: 2vh 0 3vh 0;
  }
  .error {
    color: magenta;
  }
`;

const Body = ({
  block,
  children
}: {
  block: PageBlocks;
  children?: React.ReactNode;
}) => {
  const { copy, title, hideTitle, style } = block;
  const divBot = block.showBottomDivider;
  const wave = block.waveOptions;
  const divPos = block.dividerPosition;
  const contClass = style === "Blue Background" ? "blue" : "white";
  return (
    <>
      <Wave target="top" wave={wave} />
      <ContWrap className={contClass} fluid>
        <Row className="flex-column">
          <Col>
            <BodyWrap
              className={
                block.style === "Blue Background" ? "blue" : undefined
              }>
              {hideTitle !== true && (
                <h2 className="body-copy-title">{title}</h2>
              )}
              <RichTextReact
                content={copy} options={options}
              />
            </BodyWrap>
          </Col>
          {children && <Col>{children}</Col>}
          <Wave target="bottom" wave={wave} />
        </Row>
      </ContWrap>
      {!wave && divBot && <DivBot pos={divPos} />}
      <Wave target="bottom" wave={wave} />
    </>
  );
};

export default Body;
